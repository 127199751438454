import { defineStore } from "pinia"
import type { Ref } from "vue"
import { computed, ref } from "vue"
import type { TagResponse } from "~/types/tagResponse"
import type { SearchParameter } from "~/types/search"
import type { UpdateTagNameRequest } from "~/types/updateTagNameRequest"

export const useTagSearchStore = defineStore("tagSearch", () => {
  const selectedTagNames: Ref<string[]> = ref([])
  const searchParams: Ref<SearchParameter[]> = ref([])

  const removeSearchParams = () => {
    searchParams.value = []
  }

  const removeSearchParam = (param: SearchParameter) => {
    const index = searchParams.value.indexOf(param)
    searchParams.value.splice(index, 1)

    if (param.isTag) {
      const tagIndex = selectedTagNames.value.indexOf(param.term)
      selectedTagNames.value.splice(tagIndex, 1)
    }
  }

  const selectTagName = (name: string) => {
    selectedTagNames.value.push(name)

    searchParams.value.push({ term: name, isTag: true })
  }

  const unselectTagName = (name: string) => {
    const index = selectedTagNames.value.indexOf(name)
    selectedTagNames.value.splice(index, 1)

    const searchIndex = searchParams.value.findIndex((param) => param.term === name && param.isTag)
    searchParams.value.splice(searchIndex, 1)
  }

  const unselectAllTagNames = () => {
    selectedTagNames.value = []
  }

  const tags: Ref<TagResponse[]> = ref([])

  const tagNames = computed((): string[] => tags.value.map((tag) => tag.name))

  const updateTagName = (updateRequest: UpdateTagNameRequest) => {
    const index = tags.value.findIndex((tag) => tag.name === updateRequest.oldName)

    tags.value.splice(index, 1, { ...tags.value[index], name: updateRequest.newName })

    const indexOfSelectedTag = selectedTagNames.value.indexOf(updateRequest.oldName)
    if (indexOfSelectedTag !== -1) {
      selectedTagNames.value.splice(index, 1, updateRequest.newName)
      const searchIndex = searchParams.value.findIndex((param) => param.term === updateRequest.oldName && param.isTag)
      searchParams.value.splice(searchIndex, 1, { term: updateRequest.newName, isTag: true })
    }
  }

  return {
    selectedTagNames,
    searchParams,
    tags,
    tagNames,
    removeSearchParam,
    removeSearchParams,
    selectTagName,
    unselectTagName,
    unselectAllTagNames,
    updateTagName,
  }
})
