import revive_payload_client_jS3l9xmal7 from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@3.29.4_sass@1.77.8_typescript@5.2.2_vite@5.3.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_OhayB9QhZh from "/opt/app-root/src/node_modules/.pnpm/vuetify-nuxt-module@0.14.1_rollup@3.29.4_typescript@5.2.2_vite@5.3.5_vue@3.4.34/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_hkp3cmisFR from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@3.29.4_sass@1.77.8_typescript@5.2.2_vite@5.3.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_olMVbdPpEV from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@3.29.4_sass@1.77.8_typescript@5.2.2_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rgnOf6Bb7g from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@3.29.4_sass@1.77.8_typescript@5.2.2_vite@5.3.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Vzzgs63YfI from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@3.29.4_sass@1.77.8_typescript@5.2.2_vite@5.3.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_QfdC1KpRPv from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@3.29.4_sass@1.77.8_typescript@5.2.2_vite@5.3.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_jEx7gjrHcl from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@3.29.4_sass@1.77.8_typescript@5.2.2_vite@5.3.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UICGNHHZ55 from "/opt/app-root/src/node_modules/.pnpm/@pinia+nuxt@0.5.2_rollup@3.29.4_typescript@5.2.2_vue@3.4.34/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/app-root/src/.nuxt/components.plugin.mjs";
import prefetch_client_Tgv6EOzgf3 from "/opt/app-root/src/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_rollup@3.29.4_sass@1.77.8_typescript@5.2.2_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import http_plugin_nuxt_rKkBbyjusD from "/opt/app-root/src/node_modules/.pnpm/@nuxt-alt+http@1.7.10_nuxi@3.12.0_rollup@3.29.4_sass@1.77.8_typescript@5.2.2/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import auth_plugin_6DDHMgc34l from "/opt/app-root/src/.nuxt/auth.plugin.mjs";
import vuetify_no_client_hints_kySnLgGxfb from "/opt/app-root/src/node_modules/.pnpm/vuetify-nuxt-module@0.14.1_rollup@3.29.4_typescript@5.2.2_vite@5.3.5_vue@3.4.34/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import auth_vT9JWWT9pN from "/opt/app-root/src/plugins/auth.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/opt/app-root/src/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_jS3l9xmal7,
  vuetify_icons_OhayB9QhZh,
  unhead_hkp3cmisFR,
  router_olMVbdPpEV,
  payload_client_rgnOf6Bb7g,
  navigation_repaint_client_Vzzgs63YfI,
  check_outdated_build_client_QfdC1KpRPv,
  chunk_reload_client_jEx7gjrHcl,
  plugin_vue3_UICGNHHZ55,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Tgv6EOzgf3,
  http_plugin_nuxt_rKkBbyjusD,
  auth_plugin_6DDHMgc34l,
  vuetify_no_client_hints_kySnLgGxfb,
  auth_vT9JWWT9pN,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]