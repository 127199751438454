import { default as authDotrtmqwMUMeta } from "/opt/app-root/src/pages/auth.vue?macro=true";
import { default as changelogdAzu7UU4nQMeta } from "/opt/app-root/src/pages/changelog.vue?macro=true";
import { default as indexIdyGYUy7AoMeta } from "/opt/app-root/src/pages/index.vue?macro=true";
import { default as loginED1kRNhm2UMeta } from "/opt/app-root/src/pages/login.vue?macro=true";
import { default as notification_45settingsW4Uk45LngwMeta } from "/opt/app-root/src/pages/notification-settings.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    component: () => import("/opt/app-root/src/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "changelog",
    path: "/changelog",
    component: () => import("/opt/app-root/src/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/app-root/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/app-root/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "notification-settings",
    path: "/notification-settings",
    component: () => import("/opt/app-root/src/pages/notification-settings.vue").then(m => m.default || m)
  }
]